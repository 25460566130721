import logo from "../../assets/images/logo.png";
import vplogowhite from "../../assets/images/vplogo-white.png";
import aboutSectionImg from "../../assets/images/about_section_img.png";
import drPawanpreetDhaliwal from "../../assets/images/team/dr_pawanpreet_dhaliwal.webp";
import navjotSinghSandhu from "../../assets/images/team/navjot_singh_sandhu.webp";
import ranjitSinghSandhu from "../../assets/images/team/ranjit-singh_sandhu.webp";
import myInternet from "../../assets/images/business_logo/my_internet.png";
import myWifi from "../../assets/images/business_logo/my_wifi.png";
import vpBradband from "../../assets/images/business_logo/vp_bradband.png";
import skypro from "../../assets/images/business_logo/skypro.png";
import aangan from "../../assets/images/business_logo/aangan.png";
import abacus from "../../assets/images/business_logo/abacus.png";
import colliers from "../../assets/images/business_logo/colliers.png";
import myEv from "../../assets/images/business_logo/my_ev.png";
import immigration from "../../assets/images/business_logo/immigration.png";
import myAir from "../../assets/images/business_logo/my_air.png";
import myCloudCam from "../../assets/images/business_logo/my_cloud_cam.png";
import myJet from "../../assets/images/business_logo/my_jet.png";
import punjabi from "../../assets/images/business_logo/punjabi.png";
import myHindi from "../../assets/images/business_logo/my_hindi.png";
import ashke from "../../assets/images/business_logo/ashke.png";
import dataCentrix from "../../assets/images/business_logo/data_centrix.png";
import velosting from "../../assets/images/business_logo/velosting.png";
import innovation from "../../assets/images/innovation.png";
import brandLogoBg from "../../assets/images/brand_logo_bg.png";
import emailicon from "../../assets/images/email-icon.svg";
import websiteicon from "../../assets/images/website-icon.svg";
import locationicon from "../../assets/images/location-icon.svg";
import circlehalfft from "../../assets/images/circle-half-ft.png";
import featureicon1 from "../../assets/images/feature-icon1.svg";
import featureicon2 from "../../assets/images/feature-icon2.svg";
import featureicon3 from "../../assets/images/feature-icon3.webp";
import featureicon4 from "../../assets/images/feature-icon4.svg";
import featurebgimg from "../../assets/images/feature-bg-img.png";
import feashape from "../../assets/images/fea-shape.png";
import corebgvalue from "../../assets/images/corebgvalue.png";
import borderlineimg from "../../assets/images/border-line-img.png";
import coreicon1 from "../../assets/images/coreicon1.png";
import coreicon2 from "../../assets/images/coreicon2.png";
import coreicon3 from "../../assets/images/coreicon3.png";
import coreicon4 from "../../assets/images/coreicon4.png";
import contactBg from "../../assets/images/contact_bg.webp";
import about1 from "../../assets/images/about_1.webp";
import about2 from "../../assets/images/about_2.jpg";
import aboutBgShape from "../../assets/images/about_bg_shape.png";
import blog1 from "../../assets/images/blogs/blog1.jpg";
import helpImg from "../../assets/images/help.webp";
import mission from "../../assets/images/mission.webp";
import vision from "../../assets/images/vision.webp";
import client1 from "../../assets/images/clients/client1.webp";
import client2 from "../../assets/images/clients/client2.webp";
import client3 from "../../assets/images/clients/client3.webp";
import client4 from "../../assets/images/clients/client4.webp";
import footerMap from "../../assets/images/footer-map.png";
import certificate1 from "../../assets/images/certificate/certificate1.webp";
import certificate2 from "../../assets/images/certificate/certificate2.webp";
import certificate3 from "../../assets/images/certificate/certificate3.webp";
import certificate4 from "../../assets/images/certificate/certificate4.webp";
import certificate5 from "../../assets/images/certificate/certificate5.webp";
import certificate6 from "../../assets/images/certificate/certificate6.webp";
import hiring from "../../assets/images/hiring.webp";
import teamImg from "../../assets/images/team_img.webp";
import officeImgs from "../../assets/images/office_imgs.webp";
import anniversary_img1 from "../../assets/images/events/anniversary_img1.webp";
import anniversary_img2 from "../../assets/images/events/anniversary_img2.webp";
import anniversary_img3 from "../../assets/images/events/anniversary_img3.webp";

const ImageIcons = {
  logo,
  vplogowhite,
  aboutSectionImg,
  drPawanpreetDhaliwal,
  navjotSinghSandhu,
  ranjitSinghSandhu,
  myInternet,
  myWifi,
  vpBradband,
  skypro,
  myEv,
  aangan,
  abacus,
  colliers,
  immigration,
  myAir,
  myCloudCam,
  myJet,
  punjabi,
  myHindi,
  ashke,
  dataCentrix,
  velosting,
  innovation,
  brandLogoBg,
  emailicon,
  websiteicon,
  locationicon,
  circlehalfft,
  featureicon1,
  featureicon2,
  featureicon3,
  featureicon4,
  featurebgimg,
  feashape,
  corebgvalue,
  borderlineimg,
  coreicon1,
  coreicon2,
  coreicon3,
  coreicon4,
  contactBg,
  about1,
  about2,
  aboutBgShape,
  blog1,
  helpImg,
  mission,
  vision,
  client1,
  client2,
  client3,
  client4,
  footerMap,
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
  certificate6,
  hiring,
  teamImg,
  officeImgs,
  anniversary_img1,
  anniversary_img2,
  anniversary_img3,
};

export default ImageIcons;